/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, CookieManager, Swiper) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
        ;


        /*
		|
		| Cookie Manager
		|-----------------
		*/
        new CookieManager($cookieBanner, {
            name: 'adveris_cookie_use',
            duration: 30,
            buttons: {
                accept: $cookieAccept,
                refuse: $cookieRefuse,
                close: $cookieClose
            },
            onAccept: () => {
                // console.log('accepted')
            },
            onRefuse: () => {
                // console.log('refused')
            }
        });

        /*
        |
        | Anim scroll ancre
        |-------------------------
        */
        jQuery('.achorscroll').click(function (event) {
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top
            }, 1200);
            event.preventDefault();
        });

        /*
        |
        | Réduir le menu au scroll
        |-------------------------
        */
        function minimenu() {
            if ($(window).scrollTop() > 100) {
                $('#header').addClass('mini-header');
            } else {
                $('#header').removeClass('mini-header');
            }
        }

        minimenu();
        $(window).scroll(function () {
            minimenu();
        });


        jQuery(function () {
            var selectedClass = "";
            $(".fil-cat").click(function () {

                $(".fil-cat").removeClass('active');
                $(this).addClass('active');
                selectedClass = $(this).attr("data-rel");

                $("#portfolio").fadeTo(100, 0.1);
                $("#portfolio .tile").not("." + selectedClass).fadeOut().removeClass('scale-anm');
                setTimeout(function () {
                    $("." + selectedClass).fadeIn().addClass('scale-anm');
                    $("#portfolio").fadeTo(300, 1);
                }, 300);
            });
        });


        jQuery(".item-burger").click(function () {
            $("body").toggleClass('overh');
        });

        if ($('.nbr').length) {
            var a = 0;
            // jQuery(window).scroll(function () {
            // var oTop = jQuery('.nbr').offset().top - window.innerHeight;

            // if (a == 0 && jQuery(window).scrollTop() > oTop) {
            jQuery('.count').each(function () {
                var $this = $(this),
                    countTo = $this.attr('data-count');
                jQuery({
                    countNum: $this.text()
                }).animate({
                        countNum: countTo
                    },
                    {
                        duration: 2000,
                        easing: 'swing',
                        step: function () {
                            $this.text(Math.floor(this.countNum));
                        },
                        complete: function () {
                            $this.text(this.countNum);
                            //alert('finished');
                        }

                    });
            });
            a = 1;
            // }
            // });
        }

        if ($('.mkx-modal-loop').length) {
            $('.mkx-modal-loop').iziModal();
        }


        jQuery(document).on('opened', '.modalteam', function (e) {
            $('.sliderteam').each(function (index) {
                $(this).find('.swiper-container').addClass('s' + index);
                $(this).find('.swiper-button-next').addClass('sbn' + index);
                $(this).find('.swiper-button-prev').addClass('sbp' + index);
                var slider = new Swiper('.s' + index, {
                    slidesPerView: 3,
                    spaceBetween: 20,
                    speed: 1200,
                    paginationClickable: true,
                    loop: true,
                    navigation: {
                        nextEl: '.sbn' + index,
                        prevEl: '.sbp' + index
                    }
                });
            })
        });

        if (jQuery("#mesure4").length != 0) {
            var posbottom = jQuery('section #mesure4').position().top + jQuery('section #mesure4').outerHeight(true) - 200;

            function menusurmesure() {
                if (($(window).scrollTop() > 800) && ($(window).scrollTop() < posbottom)) {
                    $('#menusurmesure').addClass('active');
                } else {
                    $('#menusurmesure').removeClass('active');
                }
            }

            menusurmesure();
            $(window).scroll(function () {
                menusurmesure();
            });
        }

        $(document).ready(function () {
            $('#menusurmesure a').on('click', function (evt) {
                evt.preventDefault();
                var target = $(this).attr('href');

                $('html, body')
                    .stop()
                    .animate({scrollTop: $(target).offset().top - 200}, 1000);
            });
        });
        $(document).ready(function () {
            $('#linkelem a').on('click', function (evt) {
                evt.preventDefault();
                var target = $(this).attr('href');

                $('html, body')
                    .stop()
                    .animate({scrollTop: $(target).offset().top - 200}, 1000);
            });
        });

        jQuery(function () {
            var selectedClass = "";
            $(".fil-tem").click(function () {

                $(".fil-tem").removeClass('active');
                $(this).addClass('active');
                selectedClass = $(this).attr("data-rel");

                $("#elemteam").fadeTo(100, 0.1);
                $("#elemteam .tile").not("." + selectedClass).fadeOut().removeClass('scale-anm');
                setTimeout(function () {
                    $("." + selectedClass).fadeIn().addClass('scale-anm');
                    $("#elemteam").fadeTo(300, 1);
                }, 300);
            });
        });


    }
}
